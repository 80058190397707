import Alpine from "alpinejs";
import Swiper, {
    Pagination,
    Parallax,
    Navigation,
    A11y,
    Autoplay,
    FreeMode,
} from "swiper/core";

/**
 * Swiper slider
 */
Alpine.data("slider", (params = {}) => ({
    swiper: null,
    shown: false,
    rafFrame: null,
    progress: 0,
    speed: 0,
    speedFactor: 0.38,
    sliderLength: 0,
    swiperOverrides: {},
    initialProgress: 0,
    init() {
        let delay = 7000;
        let speed = 950;

        let swiperParams = {
            modules: [Pagination, Parallax, A11y, FreeMode],

            // Optional parameters
            direction: "horizontal",
            loop: true,
            loopedSlides: 4,
            // speed: speed,
            // enabled: false,
            // autoplay: {
            //     delay: delay,
            //     enabled: true,
            //     disableOnInteraction: true
            // },
            breakpoints: {
                1024: {
                    slidesPerView: 2,
                },
            },
            slidesPerView: 1.2,
            centeredSlides: true,
            // rewind: true,
            freeMode: {
                enabled: true,
                sticky: false,
            },

            // parallax: {
            //     enabled: true
            // }
            ...this.swiperOverrides,
        };

        // 0.21426102492922827
        // 0.7839163948240734

        this.sliderLength = this.$el.querySelectorAll(".swiper-slide").length;
        // this.initialProgress = (1 / this.sliderLength) * 1.5;
        // this.progress = this.initialProgress;
        // console.log(this.$el, 'has that many', this.sliderLength, 'initial', this.initialProgress);
        // this.speed = 0.0112 / this.sliderLength;
        // this.speed = 0.0056 / this.sliderLength;
        this.speed = -7 / this.sliderLength;
        // this.speed = -32 / this.sliderLength;

        this.$nextTick(() => {
            this.swiper = new Swiper(this.$refs.container, swiperParams);
            this.$refs.container.classList.add("swiper-loaded");

            // Fake autoplay for free Mode
            this.rafFrame = window.requestAnimationFrame(
                this.makeProgress.bind(this)
            );
            this.swiper.on("sliderFirstMove", () => {
                window.cancelAnimationFrame(this.rafFrame);
            });
        });
    },
    makeProgress() {
        if (this.swiper.enabled) {
            let newTranslate = this.swiper.translate + (this.speed * this.speedFactor);
            let offset = this.swiper.loopedSlides;
            let notOver = this.swiper.slidesGrid[this.swiper.slidesGrid.length-offset]*-1;

            if(newTranslate <= notOver) {
                newTranslate = this.swiper.slidesGrid[offset]*-1;
            }
            this.swiper.setTranslate(newTranslate);
            this.swiper.update();
        }
        this.rafFrame = window.requestAnimationFrame(
            this.makeProgress.bind(this)
        );
    },
    ...params,
}));